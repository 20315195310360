export default {
  appName: "WikiWallet",

  /////////////////////////운영/////////////////////////////
  serverProtocol: "https",
  serverIp: "aback.wikicoin.biz",
  serverPort: "443",
    websocketUrl: 'https://aback.wikicoin.biz/sock',

  /////////////////////////개발/////////////////////////////
  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "19001",
  // websocketUrl: 'http://localhost:19001/sock',

  clientId: "",
  clientSecret: "",
  serverContext: "",
};
