import { Table, Input, Button, Modal, Select, DatePicker } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import moment from "moment";
import excelPng from "../../img/excel.png";
import { utils, writeFile } from "xlsx";
import { filterCode, statusCode, withdrawStatus } from "../../lib/util/codeUtil";
import { amountFormat } from "../../lib/util/numberUtil";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../../const";

const Search = Input.Search;
const Option = Select.Option;
const { RangePicker } = DatePicker;

const ChargeStat = () => {
    const [userInfo, setUserInfo] = useState(reactLocalStorage.getObject(Const.appName+ "#loginInfo")); 
    const [list, setList] = useState([]); 
    const [dates, setDates] = useState([moment().subtract(1, 'days'), moment()]);
    const [searchNode, setSearchNode] = useState(''); 

    useEffect(() => {
        getList();
    },[dates, searchNode]);
    
    const getList = async () => {
        let startDate = '', endDate = '';
        const [start, end] = dates;
        if (start) startDate = start.format("YYYY-MM-DD HH:mm:ss");
        if (end) endDate = end.format("YYYY-MM-DD HH:mm:ss");
        const res = await httpGet(httpUrl.chargeStat2, [startDate, endDate, searchNode], {})

        setList(res.data.externalCharges);
    };

    const onDateChange = (dates) => {
        if (dates) {
            const [start, end] = dates;
            setDates([start, end]);
        } else {
            setDates([]);
        }
    };

    const columns = [
        {
            title: "장비번호",
            dataIndex: "address",
            className: "column-center",
        },
        {
            title: "수량",
            dataIndex: "amount",
            className: "column-center",
            render: (data) => <div>{amountFormat(data, 201)} BAT</div>

            
        },
        
    ];
    const time = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
    return (
        <div className="main">
            <div className="main-title">
                일자별 채굴 목록
            </div>
            <div className="top-bar">  
                <div>
                    
                    <RangePicker
                        showTime // 시간 선택 활성화
                        onChange={onDateChange}
                        style={{ marginBottom: "10px", width: "100%" }}
                        format="YYYY-MM-DD HH:mm:ss" // 표시되는 포맷
                    />

                    <Select
                        style={{ width: "100px", marginRight: '10px' }}
                        value={searchNode}
                        onChange={value => {
                            setSearchNode(value)
                        }}>
                        <Option key={0} value={''}>
                        전체보기
                        </Option>
                        {userInfo.adminAuthNode.map((row, idx)=>
                            <Option key={idx+1} value={row}>
                                {row}
                            </Option>)}
                    </Select>
                    {/* <div>
                        <span style={{color: 'gray'}}>검색시간</span> : <b>{currentSearchCondition}</b> <span style={{color: 'gray'}}>(검색한 일시로부터 하루동안의 통계를 집계합니다.)</span>
                    </div> */}
                    
                </div>
                    <div style={{flex:1, width:'100%', justifyContent:'end', fontSize:'18px', fontWeight:'bold'}}>
                        <div>
                            총 채굴량 : {amountFormat(list.reduce((acc, curr) => acc + curr.amount, 0), 201)} BAT
                        </div>
                    </div>
            </div>
           <div className="top-table">
                <Table
                    rowKey={(record) => record.idx}
                    dataSource={list}
                    columns={columns}
                />
             </div>
        </div>
    );

}

export default ChargeStat;
