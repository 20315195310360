import React from "react";

const Footer = () => {
  return (
    <div id="footer">
    <div>WikiWallet ©2023 Created by WikiWallet.</div>
      </div>
  );
};

export default Footer;
